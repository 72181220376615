var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"dense":"","fixed":""}},[_c('div',{class:{
      bgSvg: !_vm.$vuetify.theme.dark,
      bgSvgDark: _vm.$vuetify.theme.dark
    }},[_c('v-flex',{staticClass:"backDropper",style:({
        'color:rgb(255, 255, 255,0.5)': !_vm.$vuetify.theme.dark,
        'color:rgb(0,0,0)': _vm.$vuetify.theme.dark
      })},[_c('v-flex',{staticClass:"pa-4",attrs:{"id":"main"}},[_c('v-dialog',{staticClass:"mt-5 primary",staticStyle:{"border":"none !important"},attrs:{"persistent":"","max-width":"50vw"},model:{value:(_vm.dialogProgress),callback:function ($$v) {_vm.dialogProgress=$$v},expression:"dialogProgress"}},[_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.progressMessage)+" "+_vm._s(_vm.fileProgress)+" "),_c('v-progress-linear',{staticClass:"px-n5",attrs:{"value":_vm.fileProgress,"height":"5","color":"primary"}})],1)])],1),_c('v-card',{staticClass:"mt-3 pa-5",attrs:{"elevation":"6"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"File Upload Tur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-file-input',{staticClass:"mx-4",attrs:{"outlined":"","error-messages":errors,"counter":"","multiple":"","show-size":"","label":"Attachemnt(I file upload tur thlang rawh)","outline":"","dense":""},model:{value:(_vm.fileUpload),callback:function ($$v) {_vm.fileUpload=$$v},expression:"fileUpload"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"File Upload Tur Hming","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"name":"name","label":"File Hming","id":"id"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mx-1",attrs:{"disabled":!_vm.fileUpload || !_vm.name,"color":"primary","outlined":"","depressed":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit")])],1)])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }