<template>
  <v-app dense fixed>
    <div
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark
      }"
    >
      <v-flex
        class="backDropper"
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark
        }"
      >
        <v-flex id="main" class="pa-4">
          <v-dialog
            v-model="dialogProgress"
            persistent
            max-width="50vw"
            class="mt-5 primary"
            style="border: none !important"
          >
            <v-card class="pa-4">
              <div class="text-center">
                {{ progressMessage }}
                {{ fileProgress }}
                <v-progress-linear
                  :value="fileProgress"
                  height="5"
                  color="primary"
                  class="px-n5"
                ></v-progress-linear>
              </div>
            </v-card>
          </v-dialog>
          <v-card elevation="6" class="mt-3 pa-5">
            <v-row align="center">
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="File Upload Tur"
                  rules="required"
                >
                  <v-file-input
                    outlined
                    :error-messages="errors"
                    counter
                    multiple
                    show-size
                    label="Attachemnt(I file upload tur thlang rawh)"
                    outline
                    dense
                    class="mx-4"
                    v-model="fileUpload"
                  ></v-file-input>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="File Upload Tur Hming"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    dense
                    :error-messages="errors"
                    name="name"
                    label="File Hming"
                    id="id"
                    class=""
                    v-model="name"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-center">
                  <v-btn
                    :disabled="!fileUpload || !name"
                    @click="submit()"
                    class="mx-1"
                    color="primary"
                    outlined
                    depressed
                    >Submit</v-btn
                  >
                </div>
              </v-col></v-row
            >
          </v-card></v-flex
        >
      </v-flex>
    </div>
  </v-app>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { ValidationProvider, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
extend('required', {
  ...required,
  message: '{_field_} dah khah ngei ngei tur'
})
export default {
  components: {
    ValidationProvider
  },
  data () {
    return {
      fileUpload: [],
      name: '',
      dialogProgress: false,
      progressMessage: '',
      fileProgress: 0,
      fileProg: [],
      arr: []
    }
  },
  methods: {
    async submit () {
      this.arr = []
      let newAttach
      this.dialogProgress = true

      if (this.fileUpload.length > 0) {
        this.progressMessage = 'Uploading Your Files Please Wait...'

        this.fileProg = new Array(this.fileUpload.length)
        for (let [i, v] of this.fileUpload.entries()) {
          v

          let ref = firebase
            .storage()
            .ref()
            //  .child(`userFiles/${this.$store.state.userInfo.id}`)
            .child(`icons`)
          let e = this.fileUpload[i]

          ref = ref.child(
            String(firebase.firestore.Timestamp.now().seconds).concat(
              firebase.firestore.Timestamp.now().nanoseconds,
              '.',
              e.name.toString().split('.')[1]
            )
          )

          // await new Promise((resolve) => setTimeout(resolve, 5000));
          let finalRef = ref.put(this.fileUpload[i])
          this.fileProg[i] = 0
          finalRef.on(
            'state_changed',
            async snapshot => {
              this.fileProg[i] =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              this.fileProgress = 0
              this.fileProg.forEach(e => {
                this.fileProgress = e + this.fileProgress
              })
              this.fileProgress = Math.floor(
                this.fileProgress / this.fileProg.length
              )
              if (this.fileProgress === 100) {
                this.progressMessage = 'Finished Uploading Writing to database'
              }
            },
            error => {
              this.$store.commit('changeError', error)
            },
            async () => {
              finalRef.snapshot.ref.getDownloadURL().then(async res => {
                this.arr.push(res)
                if (this.arr.length === this.fileUpload.length) {
                  newAttach = {
                    name: this.name,
                    url: this.arr
                  }

                  await firebase
                    .firestore()
                    .collection(localStorage.getItem('userDb'))
                    .doc(this.$store.state.userInfo.id)
                    .update({
                      attachment: firebase.firestore.FieldValue.arrayUnion(
                        newAttach
                      )
                    })
                    .then(
                      (this.progressMessages = 'Uploaded...'),
                      setTimeout(() => {
                        this.progressMessage = ''
                        this.fileProgress = 0
                        this.dialogProgress = false
                        ;(this.name = ''),
                          (this.fileUpload = []),
                          (this.arr = [])
                      }, 5000)
                    )
                }
              })
            }
          )
        }
      } else {
        this.progressMessages = 'Select atleast one message'
        this.dialogProgress = true
      }
    }
  },
  watch: {}
}
</script>
<style scoped>
.scroll-target {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.bgSvg {
  min-height: 100vh;
  max-height: 100vh;
  background-color: var(--v-secondary-darken--20);
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 100vh;
  max-height: 100vh;
  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.backDropper {
  min-height: 100vh;
  max-height: 100vh;
  backdrop-filter: blur(15px);
}
</style>
